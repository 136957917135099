@import "../../styles/lib";
.BannerAd {
  height: 200px;
  width: 100%;
  padding: 20px 180px;
  background-color: $darkest-gray;
  display: flex;
  align-items: center;
  @include desktop {
    height: unset;
    padding: 40px 0;
  }
  @include small-desktop {
    padding: 20px 0;
  }
  img {
    height: 100%;
    width: 100%;
    object-fit: contain;
  }
}
