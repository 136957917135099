@import "../../../styles/lib";
.LoginForm {
  max-width: 340px;
  margin: 0 auto;
  .Input {
    margin-bottom: 20px;
  }
  &__forgot_password {
    padding: 0;
    color: #000;
    font-size: 12px;
    font-weight: 500;
    text-decoration: underline;
    cursor: pointer;
    transition: $transition1;

    &:hover {
      color: lighten(#000, 20);
      text-decoration: underline;
    }
  }
  &__submit_btn {
    margin: 0 auto;
    margin-top: 20px;
    max-width: 220px;
  }
}
