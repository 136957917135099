@import "../../styles/lib";
.ShopFilters {
  transition: $transition1;
  &__wrapper {
    width: 100%;
    max-width: 300px;
  }

  border: 1px solid #dddddd;
  background-color: #fdfdfd;
  @include mobile {
    position: fixed;
    z-index: 3;
    top: 0;
    left: 0;
    height: 100%;
    width: 70%;
    overflow: scroll;
    transform: translateX(-100%);
  }

  &__overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 2;
    background-color: $dark-gray;
    transition: $transition1;
    opacity: 0;
    pointer-events: none;
  }
  &__overlay--show {
    opacity: 0.9;
    pointer-events: auto;
  }

  &__label {
    display: inline-block;
    width: 100%;
    font-size: 14px;
    letter-spacing: 20.72px;
    background: #dddddd;
    color: #fff;
    padding: 2px 0;
    text-align: center;
    font-weight: 500;
    text-transform: uppercase;
  }

  &__content {
    width: 100%;
    height: 100%;
    padding: 10px 9px;
    & > * {
      margin-bottom: 20px;
      &:last-child {
        margin-bottom: 0;
        border-bottom: unset;
      }
    }
    @include mobile {
      margin-bottom: 65px;
    }
  }

  &__select {
    .Select__control {
      height: auto;
    }
  }
  &--open {
    @include mobile {
      transform: translateX(0);
    }
  }
}
