@import "../../../styles/lib";
.FindTiresBySize {
  position: relative;
  z-index: 1;
  height: calc(100% - 40px);
  width: 100%;
  max-width: 943px;
  padding: 17px 0 40px 0;
  @include tablet {
    padding: 17px 10px 40px 10px;
  }
  // @include mobile {
  //   padding: 17px 10px 40px 10px;
  // }

  &__tabs {
    transition: $transition1;
    position: absolute;
    display: flex;
    margin: 0;
    width: unset;
    bottom: 0;
    left: 50%;
    transform: translate(-50%, 0);
  }
  &__mobile_tabs {
    position: absolute;
    height: 40px;
    bottom: 0;
    left: 50%;
    transform: translate(-50%, 0);
    max-width: 100vw;
  }

  &::after {
    content: "";
    display: inline-block;
    position: absolute;
    z-index: -1;
    top: 91px;
    left: 120px;
    width: 460px;
    height: 460%;
    background-image: url(../../../assets/images/tire-illustration.svg);
    background-repeat: no-repeat;
    clip-path: polygon(0 0, 100% 0, 100% 175px, 0 175px);
    -webkit-clip-path: polygon(0 0, 100% 0, 100% 175px, 0 175px);

    @include mobile {
      left: 50%;
      transform: translateX(-50%);
      width: 190px;
      top: 172px;
      height: 71%;
      clip-path: polygon(0 0, 100% 0, 100% 65px, 0 65px);
    }
  }
  &--no_svg {
    &::after {
      display: none;
    }
  }
}
