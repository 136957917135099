@import "./src/styles/lib";

.Faq {
  padding-bottom: 40px;
  &__heading {
    font-weight: 600;
    margin-bottom: 20px;
    line-height: 24px;
  }

  &__content {
    padding-top: 40px;
    display: flex;
    flex-wrap: wrap;
  }

  &__list {
    padding-left: 20px;
    padding-right: 20px;
    flex: 1 1;
    @include mobile {
      padding-top: 25px;
    }
  }
}
