@import "../../styles/lib";
.CheckoutConfirmation {
  &__heading {
    font-weight: 600;
    color: $dark-gray;
  }
  &__order_details {
    border-bottom: 1px solid #e3e3e3;
    padding-left: 10px;
    margin-bottom: 20px;
  }
  &__detail {
    margin-bottom: 20px;
    span {
      font-weight: 700;
      text-transform: uppercase;
    }
  }
  &__status {
    display: flex;

    .CheckoutConfirmation__detail {
      margin-left: 5px;
    }

    svg {
      width: 20px;
      margin-right: 10px;
      color: $orange;
    }
  }
  &__left {
    width: 100%;
  }

  &__wrapper {
    display: flex;
    flex-direction: row;
    gap: 20px;
    @include tablet {
      flex-direction: column;
    }
  }

  &--break {
    font-weight: 500;
    width: 100%;
    margin-top: 5px;
    margin-left: 10px;
    p {
      margin: 0;
      line-height: 22px;
    }
  }
}
