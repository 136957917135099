.PaymentMethodFields {
  display: flex;
  flex-direction: column;
  align-items: center;
  &__card_icons {
    display: flex;
    margin-bottom: 15px;
    .Icon {
      margin-right: 10px;
      height: 42px;
      width: 50px;
      svg {
        height: 50px;
        width: 50px;
      }
    }
  }

  &__description {
    font-size: 12px;
    line-height: 16px;
    font-weight: 500;
    margin-left: 27px;
  }
  &__checkbox {
    font-size: 14px;
    font-weight: 600;
    margin-bottom: 5px;
  }
  &__cod {
    width: 75px;
    height: 45px;
    margin-bottom: 15px;
  }
  &__pos {
    width: 45px;
    height: 45px;
  }

  &__row {
    padding: 20px;
  }
  &__bbottom {
    border-bottom: 1px solid #e3e3e3;
  }
}
