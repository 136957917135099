@import "../../../styles/lib";
.Button {
  display: flex;
  align-items: center;
  justify-content: center;
  background: $orange;
  width: 100%;
  height: 40px;
  padding: 0 14px;
  color: #fff;
  font-size: 16px;
  font-weight: 700;
  border: 0;
  transition: $transition1;
  text-transform: uppercase;

  &__icon {
    width: 20px;
    height: 20px;
    margin-left: 5px;
    svg {
      height: 100%;
      width: 100%;
    }
  }

  &:hover {
    background: lighten($orange, 5);
  }
  &--disabled {
    background: $gray;
    pointer-events: none;
  }
  &--small {
    height: 30px;
    font-size: 11px;

    .Button__icon {
      width: 15px;
      height: 15px;
    }
  }
}
