@import "../../../../styles/lib";

.MobileTabsSlider {
  position: absolute;
  height: 40px;
  bottom: 0;
  left: 50%;
  transform: translate(-50%, 0);
  max-width: 100vw;
  &__wrapper {
    position: relative;
    height: 40px;
    .slick-next {
      right: 60px;
      z-index: 3;
    }
    .slick-prev {
      left: 60px;
      z-index: 3;
    }
  }
  &__tab {
    justify-content: center;
  }
  //ARROWS
  &__arrow {
    width: 27px;
    height: 27px;
    svg {
      transition: $transition1;
      fill: #fff;
    }
    // svg:hover {
    // fill: darken(#fff, 5);
    // }
    &::before {
      content: unset;
    }
  }
  &__arrow--disabled {
    pointer-events: none;
  }
  &__arrow--next {
    right: -31px;
    svg {
      transform: rotateZ(180deg);
    }
  }

  &__arrow--prev {
    left: 55px;
  }

  &__arrow--next {
    right: 55px;
  }
  &__arrow {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    z-index: 2;
  }
}
