@import "../../styles/lib";
.Banner {
  display: flex;
  align-items: center;
  justify-content: space-between;

  gap: 40px;
  padding: 35px 200px;
  width: 100%;
  max-width: 1430px;
  margin: 0 auto;
  background-color: $dark-gray;
  color: $gray-text;
  margin-bottom: 20px;
  height: 140px;
  @include small-desktop {
    padding: 20px 80px;
    gap: 25px;
  }
  @include mobile {
    padding: 20px 15px;
    gap: 25px;
  }

  &__wrapper {
    display: flex;
    flex: 1 1;
    align-items: center;
  }

  &__tire_icon {
    min-width: 53px;
    height: 64px;
    width: 53px;
    svg {
      path {
        fill: #9fa4af;
      }
    }
    @include mobile {
      min-width: 38px;
      height: 40px;
      width: 38px;
      svg {
        height: 40px;
      }
    }
  }

  &__truck_icon {
    min-width: 90px;
    width: 90px;
    height: 46px;
    svg {
      height: 46px;
      path {
        fill: #9fa4af;
      }
    }

    @include mobile {
      min-width: 68px;
      width: 68px;
      height: 35px;
      svg {
        height: 35px;
      }
    }
  }
  &__safe_icon {
    min-width: 51px;
    width: 51px;
    height: 64px;
    svg {
      path {
        fill: #9fa4af;
      }
    }
    @include mobile {
      min-width: 40px;
      height: 40px;
      width: 37.75px;
      svg {
        height: 40px;
      }
    }
  }

  &__description {
    margin-left: 20px;
    // @include mobile {
    //   max-width: 30px;
    // }
    h4 {
      margin: 0;
      font-size: 16px;
      font-weight: 600;
      text-transform: uppercase;
      width: 100%;
      display: block;
    }
    p {
      display: block;
      margin: 0;
      font-size: 12px;
      font-weight: 500;
      line-height: 16px;
      width: 100%;
    }
    @include small-desktop {
      p {
        display: none;
      }
    }
    @include mobile {
      margin-left: 0;
      h4 {
        font-size: 9px;
      }
    }
  }
}
