@import "../../styles/lib";
.ImageSlider {
  width: 460px;
  height: 100%;

  @include mobile {
    width: 100%;
    height: 370px;
  }
  @media (max-width: 376px) {
    height: 280px;
  }
  .slick-slider {
    width: 100%;
    height: 100%;
    
  }
  .slick-list {
    width: 100%;
    height: 100%;
  }
  .slick-dots {
    bottom: 32px;
    button::before {
      color: #fff;
      opacity: 1;
    }
    li.slick-active button::before {
      color: $orange;
      opacity: 1;
      font-size: 10px;
    }
  }
  .slick-arrow {
    display: none !important;
  }
  &__slide {
    img {
      width: 100%;
      height: auto;

      object-fit: cover;
    }
  }
}

