@import "../../../styles/lib";
.Modal {
  top: 50%;
  transform: translateY(-50%) !important;
  margin: 0 auto;
  box-shadow: 0px 15px 30px rgba(0, 0, 0, 0.05);
  border-radius: 3px;
  .modal-content {
    border: 0;
    padding: 30px;
  }
  h3 {
    font-size: 20px;
    font-weight: 500;
    line-height: 1.1em;
    text-align: center;
    color: #334144;
    margin-bottom: 30px;
  }
}
