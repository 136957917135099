@import "./../../styles/_lib.scss";

.DefaultPage {
  @include mobile {
    margin-top: 20px;
  }

  @include tablet {
    margin-top: 50px;
  }

  &__heading {
    font-weight: 600;
    margin-bottom: 20px;
    line-height: 24px;
  }
  &__wrapper {
    display: flex;
    gap: 20px;
    @include mobile {
      flex-direction: column;
    }
  }

  &__content {
    font-size: 14px;
    font-weight: 500;
  }
  &__right {
    width: 100%;
  }
}
