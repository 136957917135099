@import "../../styles/lib";
.OrderSingle {
  &__wrapper {
    display: flex;
    @include tablet {
      flex-direction: column;
      gap: 20px;
    }
  }
  &__left {
    width: 100%;
    margin-right: 20px;
  }
  &__heading {
    color: $dark-gray;
    font-weight: 600;
    padding-bottom: 23px;
    margin-bottom: 23px;
    border-bottom: 1px solid #e3e3e3;
  }
}
