@import "../../../styles/lib";
.FindTiresByCar {
  display: grid;
  width: 100%;
  margin: auto;
  max-width: 943px;
  padding: 17px 0 40px 0;
  grid-template-columns: repeat(4, 1fr);
  grid-column-gap: 20px;

  @include mobile {
    grid-template-columns: repeat(3, 1fr);
    max-width: unset;
    padding: 17px 10px 40px 10px;
  }

  &__button {
    place-self: end;
    @include mobile {
      grid-column: 1/4;
      max-width: 200px;
      margin: 20px auto;
    }
  }
  &__description {
    margin-top: 22px;
    grid-column: 1/5;
    text-align: center;
    line-height: 18px;
    @include mobile {
      grid-column: 1/4;
    }
  }

  &__results {
    position: absolute;
    top: 467px;
    width: 100%;
    background-color: white;
  }
}
