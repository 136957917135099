@import "../../styles/lib";
.Shop {
  &__breadcrumbs {
    height: 50px;
  }
  &__content {
    display: flex;
    gap: 20px;
    @include mobile {
      flex-direction: column;
    }
  }

  &__heading {
    font-size: 22px;
    line-height: 22px;
    font-weight: 700;
    margin-bottom: 19px;
  }

  &__products {
    & > * {
      margin-bottom: 10px;
      &:last-child {
        margin-bottom: 0;
      }
    }
  }
  &__filters_button {
    position: fixed;
    bottom: 95px;
    right: 14px;
    width: 80px;
    height: 80px;
    background-color: $orange;
    border-radius: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    z-index: 999;
    &:hover {
      background-color: lighten($orange, 5);
    }
  }
  &__filter_icon {
    width: 42px;
    height: 35px;
    path {
      fill: white;
    }
  }
}
