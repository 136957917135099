@import "../../styles/lib";
.OrderSummary {
  display: flex;
  flex-direction: row;
  gap: 20px;

  @include mobile {
    flex-direction: column;
  }

  &__heading {
    font-weight: 600;
  }
  &__right {
    width: 100%;
  }
  &--break {
    font-weight: 500;
    width: 100%;
    margin-top: 5px;
    margin-left: 10px;
    p {
      margin: 0;
      // line-height: 22px;
    }
  }
  &__bank_icons {
    display: flex;
    gap: 10px;
    .Icon {
      height: 40px;
      width: 40px;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }

  &__payment_method {
    width: 100%;
    display: flex;
    align-items: center;
  }
}
