@import "../../../styles/lib";
.FindCarTires {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-column-gap: 20px;
  @include mobile {
    grid-template-columns: repeat(3, 1fr);
  }
  &__season_select {
    grid-column: 4/5;
    margin-top: 60px;
    margin-left: -127px;
    & > * {
      margin-bottom: 15px !important;
    }
    @include mobile {
      grid-area: 2/1/2/4;
      margin: 0;
      display: flex;
      justify-content: space-between;
      margin-top: 10px;
    }
  }
  &__button {
    place-self: end;
    @include mobile {
      grid-area: 3/1/3/4;
      margin: 0 auto;
      width: 200px;
    }
  }
}
